import BasePaymentController from "./BasePayment.controller";

class RazorpayPaymentController extends BasePaymentController {
  constructor() {
    super();
    if (typeof document !== "undefined") {
      this.#loadScript();
      this.paymentMethodes = {};
    }
  }

  #loadScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async initiatePayment(
    courseDetails,
    paymentType,
    name,
    email = "",
    phoneNumber = "",
    billingCountry = "",
    onSuccessPayment,
    withEMI,
    anonymousUserId
  ) {
    let order = await super.createOrder(
      courseDetails.courseSlug,
      paymentType,
      billingCountry,
      withEMI,
      anonymousUserId,
      email
    );
    let options = this.#getPaymentOption(order, name, email, phoneNumber);
    this.#openPayment(options, onSuccessPayment, courseDetails);
  }

  #openPayment(options, onSuccessPayment, courseDetails) {
    const { courseSlug, title } = courseDetails;
    let razorpay = new window.Razorpay({
      ...options,
      description: title + options.currency == "INR" ? "+18% GST" : "",
      handler: (response) => {
        this.#handlePaymentVerification(response, courseSlug, onSuccessPayment);
      },
    });
    razorpay.open();
  }

  #handlePaymentVerification = async (
    paymentResponse,
    courseSlug,
    onSuccessPayment
  ) => {
    const verificationResponse = await super.verifyPayment({
      paymentId: paymentResponse.razorpay_payment_id,
      orderId:
        paymentResponse.razorpay_order_id ||
        paymentResponse.razorpay_subscription_id,
      signature: paymentResponse.razorpay_signature,
      courseSlug,
    });

    if (verificationResponse?.current_status?.status == "PAID") {
      onSuccessPayment(verificationResponse.userCourseStatus);
    }
  };

  #getPaymentOption(order, name, email, phoneNumber) {
    let options = {
      key: process.env.NEXT_PUBLIC_RAZORPAY_ID,
      name: "Outscal",
      description: "Outscal courses",
      description: "this is an ourtscal product",
      prefill: {
        name: name,
        email: email,
        contact: phoneNumber,
      },
    };

    if (order.style?.includes("SUBSCRIPTION")) {
      options.subscription_id = order.orderId;
    } else {
      options.order_id = order.orderId;
      options.amount = order.payment.amount * 100;
      options.currency = order.payment.currency;
    }

    return options;
  }
}

export default RazorpayPaymentController;
